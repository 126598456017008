import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
export default function usePatientsList() {
  const searchQuery = ref('');
  const refListTable = ref(null);
    const currentPage = ref(1);
   const pageLength = ref(10);
   const Total = ref(0);
   const dir = ref(false);
   const toast = useToast()
   const { t } = useI18nUtils()
    const tableColumns = [

      { field: 'code_member', label: t("member_code"), sortable: false },

        { field: 'first_name', label: t("fname_lname"), sortable: false },
        { field: 'NationalIDNumber', label: t("idcard"), sortable: false },

        { field: 'group', label: t("group"), sortable: false },
        { field: 'phone', label: t("phone"), sortable: false },
        { field: 'birthdate', label: t("birthday"), sortable: false },
        
        { field: 'blood_type', label: t("blood_type"), sortable: false },

       
        { field: 'action', label: t("manage"), sortable: false },


    ]
    const Data = ref([]);
    const userStorage = JSON.parse(localStorage.getItem('userData'));
    const branch_id = userStorage.branch_id;
    const refetchData = () => {
      refListTable.value.refresh()
  }
    watch([currentPage, pageLength, searchQuery], () => {
      fetchData();
    })
    const fetchData = () => {
        const offset = pageLength.value*(currentPage.value-1);
        Data.value  = [];
        store
          .dispatch('app-patients/fetchData', {
            limit: pageLength.value,
            offset:offset,
            keywords: searchQuery.value,
           //
            //branch_id:branch_id,
            order_by:'patients.id DESC'
         
          })
          .then(response => {
          
           Data.value  = response.data.data
            console.log('value',Data.value);
            const total = response.data.total?response.data.total: Data.value.length;
           
            Total.value = total
          })
          .catch((error) => {
            console.log('error',error);
            toast({
              component: ToastificationContent,
              props: {
                title: "Error fetching Patients' list",
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
    }
    const deleteData = (data) => {
      //fetchDeleteProperty
      
      var res = store.dispatch('app-patients/deleteData',data)
      res.then(response=>{
        toast({
          component: ToastificationContent,
          props: {
            title: "Delete Success",
            icon: 'AlertTriangleIcon',
            variant: 'success',
          },
        })
        fetchData();
      }).catch(()=>{
        toast({
          component: ToastificationContent,
          props: {
            title: "Error Delete",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
  
      })
    }
    
    const importCSVData = (data) => {
      //fetchDeleteProperty
      
      var res = store.dispatch('app-patients/importCSVData',data)
      res.then(response=>{
        console.log('importCSVData',response)
        toast({
          component: ToastificationContent,
          props: {
            title: "import Success",
            icon: 'AlertTriangleIcon',
            variant: 'success',
          },
        })
        fetchData();
      }).catch(()=>{
        toast({
          component: ToastificationContent,
          props: {
            title: "Error import",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
  
      })
    }
    fetchData();
    return {
        importCSVData,
        tableColumns,
        fetchData,
        deleteData,
        Total,
        Data,
        pageLength,
        dir,
        searchQuery,
        currentPage,
        refListTable,
        t
    }
}